body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height:100%;
}
hr {
  border-top: 1px solid purple;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#progressBox {
   display: none;
   transition: 0.45s;
}
input {
  border: none;
  width: 100%;
  padding: 1rem;
  background: transparent;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border-bottom: 1px rgba(255, 255, 255, 0.384) solid;
  outline: none;
  color: #fff;
  font-weight: 300;
  font-size: 1.3em;
}
html {
  height:100%;
}
.header {
  color: rgb(0, 0, 0);
  padding: 15px;
  margin-left:0.25rem;
  font-weight: 400;
  font-size: 25px;
  display: flex;
}
.inform {
  color: white;
  font-weight: 400;
  font-size: 18px;
}